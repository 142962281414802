.service-sec-wrp {
    .each-service-sec {
        display: block;
        text-align: center;
        max-width: 225px;
        width: 225px;

        padding-bottom: 35px;
        margin: 0 auto;
        float: none;
        color: var(--White);

        .service-icon {
            width: 75px;
            display: block;
            margin: 0 auto;
        }

        h3 {
            margin-bottom: 0;
            font-family: var(--Walter);
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    &::after {
        content: '';
        width: 100%;
        height: calc(100% - 60px);
        display: block;
        background: var(--Purple);
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        width: 25px;
        height: 50px;
        display: block;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover {
        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }
}