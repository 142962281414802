.standard-form {
    max-width: 550px;
    margin: 0 auto;
    .form-group {
        padding: 0;
    }
    .standard-btn{
        background-color: #FF7940;
        border-radius: 50px;
        color: #fff;
        cursor: pointer;
        &.Mui-disabled{
            cursor: not-allowed;
            background-color: rgba(145, 158, 171, 0.24);
            color: rgba(145, 158, 171, 0.8);
            pointer-events: all;
        }
    }
}
.form-group {
    position: relative;
    .custom-textfield {
        border-radius: 4px;
        border: 1px solid #B4AC9C;
        label{
            font-size: 13px;
        }
    }
}

textarea {
    padding: 20px 12px;
    border-radius: 4px;
    border: 1px solid #B4AC9C;
    max-width: 100%;
    min-width: 100%;
}
.login-btn {
    max-width: 550px;
    margin: 0 auto;
    display: table;
}

.google-auth-btn {
    max-width: 550px;
    margin: 0 auto;
    border-radius: 50px;
    border: 1px solid #B4AC9C;
    width: 100%;
    min-height: 45px;
    color: #050E10;
    font-size: 13px;
    svg{
        margin-right: 10px;
    }
}
.divider{
    margin-top: 10px;
    margin-bottom: 10px;
}
.MuiDivider-fullWidth{
    width: 100%;
    max-width: 550px;
    .MuiDivider-wrapper {
        padding-left: calc(15px * 1.2);
        padding-right: calc(15px * 1.2);
        height: 35px;
        display: flex;
        align-items: center;
    }
}
.MuiDivider-fullWidth.MuiDivider-root::after,
.MuiDivider-fullWidth.MuiDivider-root::before{
    border-color: #B4AC9C;
}
