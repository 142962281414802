.burger-icon {
    margin-right: 20px;
    padding: 0px;
    min-width: auto;
    background-color: unset !important;
    transition: none !important;

    &:hover,
    &:focus {
        background-color: unset !important;
    }

    svg+span {
        display: none;
    }
}
#fade-menu{
    z-index: 9999;
}
.menu-main-wrp {
    .MuiPaper-root.MuiPaper-elevation {
        border-radius: 24px;
        background-color: transparent;
        &::-webkit-scrollbar {
            width: 0px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .MuiMenu-list {

        background-color: var(--White);
        border-radius: 24px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .each-menuItem {
        width: 275px;
        padding: 0;

        a {
            padding: 5px 20px;
            display: block;
            width: 100%;
            color: #5D5848;
            font-size: 16px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.5px;

            .menu-icon {
                width: 25px;
                height: 25px;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }

            span {
                color: #5D5848;
                font-size: 16px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.5px;
            }
            button:hover{
                background: transparent;
            }
        }

    }
}