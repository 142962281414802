:root{
  --Baloo: 'Baloo 2', cursive;
  --Roboto: 'Roboto', sans-serif;
  --Walter: 'Walter Turncoat', cursive;
  --Montserrat: 'Montserrat', sans-serif;
  --Cedarville: 'Cedarville Cursive', cursive;

  // color
  --Purple: #57509E;
  --Purple90: #908AC7;
  --PurpleC1: #C168FE;
  --PurpleText: #868799;
  --Orange: #FE5D37;
  --White: #FFFFFF;
  --Yellow: #FFC106;
  --Green: #5CB60B;
  --Green19: #198754;
  --Violet: #57509E;
  --Teal: #61A5A6;
  --Turquoise: #0ECAF0;
  --Red: #C3242A;
  --Text36: #363D3F;
  --Petrol: #103741;
  --Orange300: #FFF0EC;
  --Green300: #E9F3EE;
  --Yellow300: #FFF8E8;
  --Turquoise300: #E9FAFD;
  --Teal300: #E5E4F1;
  --Yellow00: #FFAE00;
}

