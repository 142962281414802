.templateList {
    .template-content-wrapper {
        height: calc(100vh - 113px);
        display: flex;
        justify-content: center;
        align-items: center;
        .bannerBG {
            position: fixed;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            min-height: calc(100vh - 113px);
            filter: blur(20px);
            -webkit-filter: blur(20px);
            span {
                display: block;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }

            }

        }

        .templateList-wrp {
            .sqr-btn {
                display: block;
                padding: 10px 40px;
                border-radius: 50px;
                background: #fff;
                color: #fe5d37;

                &:not(:first-child){
                    margin-top: 30px;
                }
            }
        }
    }
}